.form-check {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.form-check-input {
    margin-top: 0;
    margin-right: 10px;
    vertical-align: middle;
}

.form-check-label {
    margin-bottom: 0;
    line-height: 1.5;
    display: block;

}