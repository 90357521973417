.register-form-container {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.register-form-title {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 15px;
}

.form-control {
    border-radius: 6px;
    padding: 10px;
    border: 1px solid #ced4da;
}

.form-check-label {
    font-size: 0.95rem;
}

.alert {
    margin-top: 10px;
    padding: 15px;
    border-radius: 6px;
}

.btn {
    border-radius: 5px;
    padding: 10px 15px;
}

.checkbox-text {
    display: block;
    margin-top: 5px;
}

.btn-primary {
    width: 100%;
    margin-top: 10px;
}

@media (min-width: 768px) {
    .btn-primary {
        width: auto;
    }
}

#infoModalMessage {
    font-size: 100% !important;
}


.btn-custom {
    font-size: 1.4rem;
    padding-left: 70px !important;
    padding-right: 70px !important;
    display: block; 
    margin: 0 auto; 
}

@media (max-width: 768px) {
    .btn-custom {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
