.navbar {
    background-color: #FFF !important;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
    background-color: #FAFAFA !important;
}

.navbar-brand {
    color: #777;
}

#askAnythingButton {
    border: 1px solid #AAAAFF !important;
    color: #0039FF;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
    transition: all 0.3s;
    text-decoration: none;
    display: inlineBlock;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 767px) {
    .navbar .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .navbar .navbar-brand {
        flex-grow: 1;
    }

    .navbar .navbar-nav {
        flex-grow: 0;
    }

    .navbar .nav-item {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}