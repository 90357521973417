.me-image-container {
  padding: 10px;
  text-align: center;
}

.me-image {
  transition: transform 0.3s;
  z-index: 1;
  flex: 1;
  width: 140px !important;
  height: 140px !important;
  margin: 10px;
}




.me-image2 {
  z-index: 1;
  flex: 1;
  max-height: 450px !important;
  max-width: 450px !important;
}


.me-image:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.a_link {
  color: #22A !important;
  text-decoration: none !important;
}

.a_link:visited {
  color: #22A !important;
  text-decoration: none !important;
}

.a_link2 {
  color: rgb(53, 53, 270) !important;
  text-decoration: none !important;
}

.a_link2:visited {
  color: rgb(53, 53, 270) !important;
  text-decoration: none !important;
}


.a_link:hover {
  text-decoration: underline !important;
}

@media (min-width: 992px) {
  .text-header {
    margin-left: -250px;
    width: 50% !important;
  }

  .box-right {
    margin-left: 7% !important;
  }
}

.movie_container {
  margin-left: 30px;
  margin-right: 30px;
}

@media (max-width: 992px) {

  .movie_container {
    margin-left: 2px;
    margin-right: 2px;
  }

  .me-image-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .me-image:hover {
    transform: scale(1) !important;
  }

  .text-header {
    padding-top: 200px;
    width: 100% !important;
    margin-bottom: 30px;
  }

  .icon-image {
    margin-bottom: 20px;
  }

  .me-image {
    transition: transform 0.3s;
    z-index: 1;
    width: 80px !important;
    height: 80px !important;
    margin: 5px;
    margin-top: 12px;
  }

  .prezentare_viorel {
    margin-top: 15px !important;
  }

  .box-right {
    margin-left: 0% !important;
  }


}

.box-left {
  margin-left: 0%;
}



.advantage-box {
  margin-bottom: 20px;
}

.icon-image {
  opacity: 0.65;
  transition: opacity 0.3s;
  width: 100px;
  height: 100px;
}

.icon-image:hover {
  opacity: 1;
  cursor: pointer;
}

li {
  margin-top: 10px;
}

.label_text {
  font-size: 1.2em;
  color: #EEE;
}


.label_text2 {
  font-size: 1.4em;
  color: #333;
  font-weight: bold;
}


.label_text_small2 {
  font-size: 0.9em;
  color: #555;
  font-weight: normal;
}

.label_text_summary {
  font-size: 0.8em;
  color: #555;
  font-weight: normal;
  padding-bottom: 10px;
  padding-top: 10px;
}

.label_text_summary_large {
  font-size: 1em;
  color: #333;
  font-weight: normal;
  padding-bottom: 10px;
  padding-top: 10px;
}

.label_header {
  font-size: 1.5em;
  color: #DDD;
}


.font_size_1_5rem {
  font-size: 1.5rem !important;
}

.font_size_1_2rem {
  font-size: 1.2rem !important;
}

.curs_desktop {
  margin-top: -190px;
  font-size: 2.4rem;
  z-index: 10;
  color: #000;
  font-family: "Archivo Black", sans-serif;
}

.curs_mobile {
  font-size: 1.8rem;
  z-index: 10;
  color: #000;
  font-family: "Archivo Black", sans-serif;
}

.lectii_desktop {
  margin-top: 10px;
  font-size: 2rem;
  z-index: 10;
  color: #000;
}

.lectii_mobile {
  font-size: 1.5rem;
  z-index: 10;
  color: #000;
  font-family: "Open Sans", sans-serif;
}



body,
html {
  height: 100%;
}

.bg-gradient-primary-to-secondary {
  background: #1e30f3;
  background: linear-gradient(135deg, #1e30f3 0%, #e21e80 100%);
}

.text-gradient {
  background: -webkit-linear-gradient(315deg, #1e30f3 0%, #e21e80 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rounded-4 {
  border-radius: 1rem !important;
}

.feature {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
}


.modalClass {
  width: 90%;
}

.bg-1 {
  --bs-bg-opacity: 1;
  background-color: #090C33 !important;
}

.bg-2 {
  --bs-bg-opacity: 1;
  background-color: #DFEFFF !important;
}

.form-check-input {
  border: 1px solid #AAA !important;
}

input,
textarea {
  font-size: 1.2em !important;
}


input.form-control:disabled {
  color: #AAA !important;
}

textarea.form-control:disabled {
  color: #AAA !important;
}

.btn-secondary:disabled {
  background-color: #EEE !important;
  border: none !important;
}

.btn-primary:disabled {
  border: none !important;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-input {
  margin-right: 10px;
  margin-top: 0;
  vertical-align: middle;
}

.alert {
  font-size: 1.5em !important;
}

.navbar-toggler-icon {
  display: none !important;
}

.navbar-toggler {
  display: none !important;
}